.leave-review-container {
    display: flex;
    border-color: transparent;
    width: 90%;
    margin-top: 1rem;
    flex-direction: column;
    align-self: center;
}

.leave-review-input {
    display: flex;
    background-color: rgba(0, 0, 0, .05);
    border-color: transparent;
    width: 90%;
    margin-top: .5rem;
    flex-direction: row;
    align-self: center;
    padding: .8rem;
    font-size: 1rem;
    font-family: Gilroy-Medium;
}

.leave-review-input:focus {
    outline: none;
    background-color: rgba(0, 0, 0, .08);
    border-bottom-color: #4169E1;
    border-bottom-width: 3px;
}

.leave-review-input-label { 
    font-family: Gilroy-Bold;
    font-size: 1rem;
    margin: 0;
    width: 95%;
    align-self: center;
    color: rgba(0, 0, 0, .75);
    letter-spacing: .4px;
}

.leave-review-input-container { 
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
}

.submit-review-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 2rem;
    background-color: #4169e1;
    margin-top: 1rem;
    border: #4169e1 solid 1px;
}

.submit-review-btn:hover {
    cursor: pointer;
}

.submit-review-btn p{
    color: white;
    font-family: Gilroy-Bold;
    text-align: center;
    font-size: 12px;
}

#leave-review-header {
    font-family: 'Gilroy-Bold';
    font-size: 1.25rem;
    margin: 0;
}

#close-leave-review {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(250, 250, 250); 
    margin: 0;
    background-color: rgb(194, 194, 194);
    border-radius: 50px;
    width: 1.25rem;
    height: 1.25rem;
}

#close-leave-review:hover {
    cursor: pointer;
}

#leave-rating-star:hover {
    cursor: pointer;
}