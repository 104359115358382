.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.day-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.day {
    margin-top: .25rem;
    margin-bottom: .25rem;
    font-size: 1rem;
    font-family: Gilroy-Regular;
}

.time {
    margin-top: .25rem;
    margin-bottom: .25rem;
    font-size: 1rem;
    font-family: Gilroy-Regular;
}