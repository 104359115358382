.location-services-banner {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;    
    -webkit-box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
    -moz-box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
    box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 2rem;
    max-width: 900px;
}

.location-services-banner div #headertext {
    color: rgb(72, 72, 72);
    font-family: Gilroy-Bold;
    font-size: 1.25rem;
    margin: 0;
    margin-bottom: 1rem;
}

.location-services-banner div #subtext {
    font-family: Gilroy-Medium;
    font-size: 1rem;
    letter-spacing: .2px;
    line-height: 150%;
    margin: 0;
}

.location-services-banner div button {
    border: 0;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    letter-spacing: .2px;
    padding: 1rem;
    background-color: #4169e1;
    color: white;
    margin-top: 1rem;
}

.location-services-banner div button:hover {
    cursor: pointer;
}