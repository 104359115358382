.floating-details-container {
    display: none;
    -webkit-box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
    -moz-box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
    box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
}

.floating-details-container .about-container {
    width: 90%;
}

.floating-details-container .hours-container {
    width: 90%;
}

@media only screen and (min-width: 900px) {
    .floating-details-container {
        display: flex;
        flex-direction: column;
        width: 30vw;
        height: fit-content;
        padding-bottom: 1rem;
        min-width: 21rem;
        max-width: 23rem;
    }
}
