#company-header {
    background-color: #4169E1;
    position: absolute;
    top: 0;
    width: 100%;
}

#home-page-header {
    position: absolute;
    top: 0;
    width: 100%;
}

#main-header {
    display: flex;
    width: '100%';
    background-color: transparent;
    padding: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#banner-header {
    background-color: #b6c4ed;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
}

#coorly-business-link {
    margin: 0;
    color: black;
    text-decoration-color: black;
}

header .business-name {
    font-family: Gilroy-Bold;
    font-size: 1rem;
    color: white;
    margin: 0;
    margin-right: 5%;
}

#header-text {
    font-family: Gilroy-Bold;
    font-size: 2rem;
    color: white;
    text-align: center;
    margin: 0;
    margin-left: 2rem;
    text-decoration: none;
}

#header-text:visited {
    text-decoration: none;
}

.header-link {
    font-family: Gilroy-Medium;
    font-size: 1rem;
    color: white;
    margin: 0;
    margin-right: 5%;
    text-decoration: none;
    white-space: nowrap;
    align-self: center;
}

.header-link:visited {
    text-decoration: none;
}

#header-link-boxed {
    font-family: Gilroy-Medium;
    font-size: 1rem;
    background-color: white;
    padding: .5rem;
    color: black;
    margin: 0;
    text-decoration: none;
    white-space: nowrap;
}

#header-link-boxed:visited {
    text-decoration: none;
}

.header-links-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-right: 3rem;
}

#mobile-check .searchbar-container {
    display: flex;
    justify-content: space-around;
}

#mobile-check .search-inputs-container {
    display: flex;
    justify-content: space-around;
    width: 80%;
}

@media only screen and (max-width: 800px) {

    .header-links-container {
        margin-right: 1rem;
    }

    #header-text {
        margin-left: 0;
    }
    
}