.coorly-title {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.coorly-title h1 {
    font-size: 3rem;
    color: #4169E1;
    font-family: Gilroy-Bold;
}