.review-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.identifier-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 40%;
}

.identifier-container div {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.identifier-container img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.identifier-container #name {
    color: #4169e1;
    font-family: Gilroy-Medium;
    margin: 0;
    font-size: 1rem;
}

.identifier-container #upload-time {
    color: rgba(0, 0, 0, .25);
    font-family: Gilroy-Regular;
    margin: 0;
    font-size: 11px;
    margin-top: .5rem;
}

.other-container {
    margin-left: 2rem;
    word-wrap: anywhere;
    width: 100%;
}

.other-container #review {
    font-family: Gilroy-Regular;
    margin: 0;
    font-size: 1rem;
    letter-spacing: .2px;
}

@media only screen and (max-width: 650px) {
    .identifier-container {
        flex-direction: column;
        width: 30%;
    }

    .identifier-container div {
        margin: 0;
        margin-top: 1rem;
    }

    .identifier-container #upload-time {
        font-size: .75rem;
    }
}