@font-face {
  font-family: "Gilroy-Light";
  src: url(./fonts/Gilroy-Light.ttf) format('truetype');
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url(./fonts/Gilroy-Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url(./fonts/Gilroy-Medium.ttf) format('truetype');
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url(./fonts/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: "Gilroy-Heavy";
  src: url(./fonts/Gilroy-Heavy.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow-x: none;
}

:-webkit-direct-focus {
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 0px!important;
}
  
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
  
*:focus {
  outline: none;
}

ul {
  margin: 0;
  padding-left: 1rem;
}
