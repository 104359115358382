.coorly-checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    background-color: white;
}

.service-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-top: 7.5rem;
}

.time-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin-top: 1rem;
}

.sub-time-container {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-container-row {
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
    width: 100%; 
}

.checkout-input {
    display: flex;
    background-color: rgba(0, 0, 0, .05);
    border-color: transparent;
    width: 90%;
    margin-top: 1rem;
    flex-direction: row;
    padding: 1rem;
    font-size: 1rem;
    font-family: Gilroy-Medium;
    height: 1rem;
    border-radius: 0;
}

.checkout-input:focus {
    outline: none;
    background-color: rgba(0, 0, 0, .08);
    border-bottom-color: #4169E1;
    border-bottom-width: 3px;
}

.time-btn {
    display: flex;
    border-style: solid;
    border-width: 1.5px;
    border-color: lightgrey;
    margin: .75rem;
    padding: .5rem;
    width: 4rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.time-btn:hover {
    cursor: pointer;
    background-color: rgb(221, 221, 221);
}

.time {
    font-size: .95rem;
    font-family: Gilroy-Medium;
    text-align: center;
    white-space: nowrap;
}

.checkout-buttons-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-self: center;
    align-items: center;
    justify-content: space-between;
}

.checkout-button {
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-color: #4169E1;
    color: #4169E1;
    font-size: 1rem;
    font-family: Gilroy-Bold;
    margin-top: 1rem;
    padding: 1rem;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    text-align: center;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
}

.checkout-button:disabled {
    color: #4169E1;
    white-space: nowrap;
}

.checkout-button:hover {
    cursor: pointer;
}

.checkout-button:disabled:hover {
    cursor: not-allowed;
}

.time-frame {
    font-family: 'Gilroy-Medium';
    margin: 0;
}

#addServiceBtn {
    background-color: #4169e1;
    color: white;
    border: none;
}

#addServiceBtn:disabled {
    background-color: darkgrey;
}

#big-screen-config {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

#service {
    font-family: Gilroy-Medium;
    font-size: 1rem;
}

#price {
    font-family: Gilroy-Bold;
    font-size: 1rem;
}

#selected {
    background-color: #4169e1;
    color: white;
}

#selected-method {
    background-color: #4169E1;
    color: white;
}

.checkout-input-label { 
    font-family: Gilroy-Bold;
    font-size: 1rem;
    margin-bottom: 0;
    width: 95%;
    color: rgba(0, 0, 0, .75);
    letter-spacing: .4px;
}

.checkout-input-container { 
    display: flex;
    flex-direction: column;
    width: 90%;
    align-self: center;
}

.time-scroll {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 100vw;
}

.additional-services-container {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-self: center;
    overflow-x: scroll;
    overflow-y: hidden;
}

.addon-container {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-self: center;
    flex-shrink: 0;
    overflow-x: scroll;
    overflow-y: hidden;
}

hr {
    width: 90%;
    border-top-style: none;
}

@media only screen and (min-width: 800px) {

    .checkout-buttons-container {
        flex-direction: row;
    }

    .checkout-button {
        margin-top: 2rem;
        width: 47.5%;
    }

    .input-container-row {
        width: 90%;
        flex-direction: row;
        align-self: center;
    }

    .checkout-input {
        width: 90%;
    }

    #big-screen-config {
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: center;
        justify-content: center;
    }

    .time-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 70%;
        overflow-x: scroll;
        height: 20rem;
        overflow-y: scroll;
    }

    .time-scroll {
        overflow-x: hidden;
        flex-wrap: wrap;
        width: 110%;
    }
}