.confirmation-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 100%;
    box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
    padding-bottom: 2rem;
    max-width: 600px;
    margin: auto; /* Center the div horizontally */
}

.confirmation-container #header {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #4169e1;
    height: 3rem;
    margin-bottom: 2.5rem;
}

.business-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.business-info a {
    margin: 0;
    margin-top: .5rem;
    font-size: 1.125rem;
    font-family: 'Gilroy-Bold';
    text-decoration: none;
    color: black
}

.business-info #phone {
    margin: 0;
    margin-top: .25rem;
    font-size: 1rem;
    font-family: 'Gilroy-Medium';
}

.appointment-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem 0 1rem 0;
}

.info-row {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 90%;
    justify-content: space-between;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.cancel-button {
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-color: #FF605C;
    color: #FF605C;;
    font-size: 1rem;
    font-family: Gilroy-Bold;
    margin-top: 1rem;
    padding: 1rem;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    width: 80%;
    background-color: white;
    text-align: center;
}

.cancel-button:hover {
    cursor: pointer;
}

#business-thumbnail {
    width: 100px;
}

#short-logo {
    width: 4rem;
    background-color: #4169e1;
    margin-top: 2rem;
    padding-bottom: .75rem;
    padding-right: .5rem;
    padding-left: .5rem;
    border-radius: 50%;
}

#label {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 1rem;
}

#description {
    margin: 0;
    font-family: 'Gilroy-Medium';
    font-size: 1rem;
}

#cancel {
    color: #FF605C;
}

@media only screen and (max-width: 800px) {

    .appointment-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        width: 90%;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        max-width: 600px;
    }

}