.service-box {
    display: flex;
    border: rgb(242, 241, 240) 1px solid;
    flex-direction: column;
    margin-top: .5rem;
    margin-bottom: .5rem;
    padding: .75rem;
    border-bottom: #4169E1 3px solid;
}

.service-box p {
    margin: 0;
}

#service-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Gilroy-Medium';
    letter-spacing: .2px;
    margin-bottom: .25rem;
    font-size: 1rem;
}

#service-desc {
    font-family: 'Gilroy-Medium';
    letter-spacing: .2px;
    font-size: .8rem;
    white-space: pre-wrap;
    width: 85%;
}

#service-price {
    font-family: 'Gilroy-Medium';
    letter-spacing: .2px;
    font-size: .9rem;
}

#service-time {
    font-family: 'Gilroy-Medium';
    letter-spacing: .2px;
    font-size: .8rem;
}

#see-times-btn {
    background-color: #4169E1;
    padding: .8rem;
    flex-direction: row;
    align-self: center;
    align-items: center;
    font-size: .8rem;
    color: white;
    font-family: Gilroy-Bold;
    text-align: center;
    text-decoration: none;
}

#see-times-btn:hover {
    background-color: #1546d9b2;
}