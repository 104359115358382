div h2 {
    align-self: flex-start;
    font-family: Gilroy-Medium;
}

.coorly-business {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    background-color: white;
}

.main-container {
    margin-top: 9rem;
    width: 90%;
}

.image-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-bottom: 2rem;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.services-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    overflow-y: scroll;
}

.reviews-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    align-items: center;
}

.location-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    align-items: center;
}

.packages-header-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
}

.packages-options-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
    margin: 1rem;
}

.about-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.hours-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.mobile-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-self: center;
}

.social-media-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(194, 194, 194);
    border-radius: 50px;
}

.vehicle-type-option {
    font-family: Gilroy-Bold;
    font-size: 1.1rem;
    margin: 0;
    margin-right: 16px;
}

.vehicle-type-option:hover {
    cursor: pointer;
}

.vehicle-type-option#selected {
    background-color: unset;
    color: unset;
    border-bottom: 3px #4169e1 solid;
}

#address {
    color: black;
    font-family: Gilroy-Regular;
    letter-spacing: .2px;
}

#bioHeader {
    font-family: Gilroy-Bold;
    font-size: 1rem;
    letter-spacing: .2px;
    margin-bottom: .5rem;
}

#bio {
    margin: 0;
    font-family: Gilroy-Light;
    font-size: 1rem;
    letter-spacing: .2px;
    white-space: pre-wrap;
    line-height: 140%;
}

#business-social-link {
    font-family: Gilroy-Medium;
    font-size: 1rem;
    text-decoration: underline;
    white-space: nowrap;
    align-self: center;
    color: black;
    margin: 0;
}

#picture-nav-arrow:hover {
    cursor: pointer;
}

@media only screen and (min-width: 900px) { 

    .packages-header-container {
        flex-direction: row;
    }

    .packages-options-container {
        flex-direction: row;
        width: auto;
        margin: 0;
        margin-left: 1rem;
    }

    .everything-else-container {
        display: flex;
        flex-direction: column;
        width: 55%;
    }

    .main-container {
        display: flex;
        flex-direction: row;
        width: 90%;
        justify-content: space-between;
    }

    .mobile-details {
        display: none;
    }    

    .reviews {
        max-height: 80vh;
    }

    .services-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: hidden;
    }
}

@media only screen and (max-width: 500px) { 
}
