.search-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 7rem;
    padding-bottom: 20rem;
    width: 100%;
    overflow-x: hidden;
    width: 90%;
}

.results-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    align-self: center;
}