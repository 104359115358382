.searchbar-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.search-inputs-container {
    display: flex; 
    flex-direction: row;
}

.searchbar-query-container {
    padding: .5rem;
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    width: 40%; 
    height: 1.5rem;
    margin: .5rem;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.searchbar-query-container:focus-within {
    border-bottom-color: #4169E1;
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.searchbar-query {
    font-family: Gilroy-Medium;
    border: none;
    background-color: transparent;
    font-size: .9rem;
    width: 85%;
    margin-left: .5rem;
}

#search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    text-decoration: none;
    border-radius: 4px;
    width: 2rem; 
    height: 2rem;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 700px) {
    
    .search-inputs-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
        height: 7rem;
    }

    .searchbar-query-container {
        width: 80%;
    }
}