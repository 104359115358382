.search-result-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.search-result-container #header {
    font-size: 1.5rem;
    font-family: Gilroy-Bold;
    margin: 0;
    width: 100%;
}

.search-service-container {
    color: unset;
    text-decoration: none;
}

.search-service-container p {
    color: #4169e1;
    font-size: .8rem;
    padding: 1rem;
    padding-top: .65rem;
    padding-bottom: .65rem;
    margin: .5rem;
    font-family: Gilroy-Medium;
    border: 1px #4169E1 solid;
    letter-spacing: .4px;
}

.search-service-container p:hover {
    background-color: #4169e1;
    color: white;
}

@media only screen and (max-width: 800px) {
    .search-result-container {
        display: flex;
        flex-direction: column;
    }    

    .search-services-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .search-result-container .new-companybox-link {
        width: 90%;
    }
}
