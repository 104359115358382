.new-companybox-link {
    display: flex;
    background-color: rgba(0, 0, 0, 0.05);
    justify-content: space-between;
    flex-direction: column;          
    width: 300px;
    height: 200px;
    margin-left: .75rem;
    margin-right: .75rem;
    text-decoration: none;
    color: unset;
    background-size: cover;
    flex-shrink: 0;
    transition: 0.3s;
}

.new-companybox-link:hover {
    transform: translate(0, -10px);
}

.new-companybox-name {
    margin: 0;
    color: white;
    font-size: 1.125rem;
    font-family: Gilroy-Bold;
    width: auto;
}

.new-companybox-location {
    margin: 0;
    color: white;
    font-size: 1rem;
    font-family: Gilroy-Regular;
    width: auto;
}

.new-companybox-ratings-container {
    margin: 0;
    display: flex;
    flex-direction: row;
}

.new-companybox-rating {
    color: white;
    margin: 0;
    font-size: 1rem;
    font-family: Gilroy-Regular;
    width: auto;
    align-self: center;
    letter-spacing: .9px;
    margin-bottom: -8px;
}

#type-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
}

#type-text {
    margin: 0;
    font-size: .75rem;
    padding: .25rem;
    color: white;
    font-family: Gilroy-Medium;
}