.ratings-card {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 90%;
    -webkit-box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
    -moz-box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
    box-shadow: 0px 0px 20px -6px rgba(65,105,225,0.5);
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    max-width: 360px;
}

.main-star-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(245, 248, 255);
    padding: 1rem;
    padding-bottom: 0;
    padding-top: 0;
    border-radius: 50px;
}

.distribution-container {
    width: 80%;
}

.distribution-meter {
    width: 70%;
    height: 1rem;
    background-color: rgb(245, 248, 255);
    border-radius: 50px;
}

.distribution {
    height: 1rem;
    background-color: rgb(255, 202, 82);
    border-radius: 50px;
    -webkit-box-shadow: 0px 0px 10px -4px rgba(255,203,82,1);
    -moz-box-shadow: 0px 0px 10px -4px rgba(255,203,82,1);
    box-shadow: 0px 0px 10px -4px rgba(255,203,82,1);
}

.distribution-row {
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

#leave-rating-btn {
    font-family: 'Gilroy-Medium';
    color: #4169e1; 
    text-decoration-line: underline;
    font-size: .75rem;
    margin: 0;
}

#leave-rating-btn:hover {
    cursor: pointer;
}

#ratings-card-header {
    font-family: 'Gilroy-Bold';
    font-size: 1.25rem;
    margin: 0;
    margin-bottom: .25rem;
}

#distribution-amount {
    font-family: 'Gilroy-Bold';
    margin: 0;
}

#star-type {
    font-family: 'Gilroy-Medium';
    font-size: .75rem; 
    color: #4169E1;
    margin: 0;
}