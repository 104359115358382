.home-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 75px;
    width: 100%;
    align-items: center;
    overflow-x: hidden;
}

.homepage-section {
    display: flex;
    width: 90%;
    font-size: 1.5rem;
    font-family: Gilroy-Bold;
    display: flex;
    flex-direction: column;
}

.homepage-section-header {
    margin-bottom: .5rem ;
}

.companybox-row {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: auto;
    height: 210px;
    align-items: flex-end;
}

.servicebox-row {
    display: flex; 
    flex-direction: row;
    width: 90%;
    overflow-x: scroll; 
    align-self: center; 
    justify-content: center;
}

.video-container {
    width: 100%;
    height: 60vh; /* Adjust the height as needed */
    background: url('../../public/media/202306182041.mp4') no-repeat;
    background-size: cover;
    margin: 0;
}

.overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.375); /* Adjust the opacity as needed */
    position: relative;
}

.overlay p {
    font-size: 1.5rem;
    font-family: Gilroy-Bold;
    color: white;
    text-align: center;
}

.business-redirect {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    position: absolute;
    bottom: 0;
}

.business-redirect p {
    font-family: Gilroy-Bold;
    font-size: 1rem;
    margin-left: .75rem;
}

#search-container {
    display: flex;
    flex-direction: row; 
    align-items: center;
    width: 80%;
}

#search-container-text {
    color: white;
    font-family: Gilroy-Medium; 
    margin: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}

#coorly-icon {
    height: 40px;
    width: 40px;
    border-radius: 8px;
}

#get-started-btn {
    font-family: Gilroy-Medium;
    font-size: 1.25rem;
    background-color: #4169E1;
    padding: 1rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: white;
    margin: 1rem;
    text-decoration: none;
    white-space: nowrap;
}

#get-started-btn:visited {
    text-decoration: none;
}

#ad-subtext {
    font-family: Gilroy-Medium;
    font-size: 1rem; 
    width: 75%; 
    text-align: center;
    margin: 12px;
    letter-spacing: -.14px;
}

#coorly-screenshot {
    width: 90%;
}

#ad-motto {
    font-size: 2rem;
    margin: 0;
    margin-top: 12px;
}

@media only screen and (max-width: 700px) {

    #search-container {
        display: flex;
        flex-direction: column; 
        align-items: center;
    }

    #search-container-text {
        font-family: Gilroy-Medium; 
        margin: 0;
        margin-top: .75rem;
        margin-bottom: .75rem;
    }

    .business-redirect p {
        font-size: .75rem;
    }

    #ad-subtext {
        width: 90%; 
    }

    #ad-motto {
        font-size: 1.5rem;
    }
}