.business-info-banner {
    display: flex;
    align-self: center;
    align-items: center;
    justify-self: center;
    flex-direction: row;
    height: 3.5rem;
    border: rgb(207, 207, 207) 1px solid;
    margin-bottom: 1rem;
    padding: .5rem;
    justify-content: space-around;
    width: 100%;
    max-width: 30rem;
}

.banner-social-media-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: rgb(194, 194, 194);
    border-radius: 50px;
    margin: .25rem;
}

.business-info-banner p {
    margin: 0;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    color: 'black';
    letter-spacing: .4px;
    margin-top: 1px;
    margin-bottom: 1px;
}

.banner-phone {
    margin: 0;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    color: black;
}

.banner-star-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.banner-star-container p {
    margin: 0;
}